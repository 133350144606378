/**
 * A class to manage instances of `Delay` objects, ensuring only one instance exists for a given key.
 */
class DelayInstanceMap {
  /**
   * A private map to store the `Delay` instances.
   * @type {Object}
   * @private
   */
  #map = {};
  /**
   * Adds a `Delay` instance to the map, deleting any existing instance with the same key.
   * @param {string} key - The key to associate with the `Delay` instance.
   * @param {Delay} instance - The `Delay` instance to add.
   */
  add(key, instance) {
    if (this.#map[key]) {
      this.delete(key);
    }
    this.#map[key] = instance;
  }
  /**
   * Get a `Delay` instance from the map
   * @param {string} key - The key of the `Delay` instance to get.
   */
  getInstance(key) {
    return this.#map[key];
  }
  /**
   * Deletes a `Delay` instance from the map, canceling it if it exists.
   * beware of race condition, this `cancel()` is happened in microtask.
   * so the original task which we want to `cancel` would be executed AFTER
   * the stack queue finished.
   * @param {string} key - The key of the `Delay` instance to delete.
   */
  delete(key) {
    const instance = this.#map[key];
    if (instance) {
      instance.cancel();
      delete this.#map[key];
    }
  }
}

export default DelayInstanceMap;
